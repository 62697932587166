<template>
  <div class="container relative mx-auto py-0 pt-2 sm:py-16">
    <transition
      enter-active-class="miniFadeInTop"
      leave-active-class="miniFadeOutBottom"
      mode="out-in"
    >
      <router-view name="page" :plans="plans" class="relative z-10" />
    </transition>
  </div>
</template>

<script>
export default {
  props: ['plans'],
  computed: {
    background: function () {
      return this.$route.meta.background;
    },
    styleObject: function () {
      return 'transform: rotate(' + (this.background.rotate || 0) + 'deg)';
    }
  }
};
</script>

<style lang="scss" scoped>
.blob-container {
  width: 380px;
}
.outline-fill {
  @apply fill-current;
}
.rotate-12 {
}
</style>
